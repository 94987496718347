import { ObjInterface } from "@/types/utils";
import { AES, BASE64 } from "@/utils/crypto";

const cryptoTypes: any = { AES, BASE64 };

export const encrypt = (data: any, type: string) => {
  if (!cryptoTypes[type] || !type) {
    throw Error(`Encrypt type: '${type}' not found!`);
  }

  if (!data) throw Error(`Data not found!`);

  return cryptoTypes[type]().encrypt(data);
};

export const decrypt = (data: any, type: string) => {
  if (!cryptoTypes[type] || !type) {
    throw Error(`Decrypt type: '${type}' not found!`);
  }

  if (!data) throw Error(`Data not found!`);

  return cryptoTypes[type]().decrypt(data);
};

export const copy = (obj: any): ObjInterface => {
  return JSON.parse(JSON.stringify(obj));
};

export const stripHtml = (html: string | undefined) => {
  if (!html) return "";

  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const jsonToQueryString = (params: {
  [key: string]: number | string;
}) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getDomain = () => {
  const domain = location.hostname;

  if (domain === "localhost") {
    return domain;
  }

  const parts = domain.split(".");
  const topLevelDomain = parts.slice(-2).join(".");
  return `.${topLevelDomain}`;
};

export const setCookie = (
  name: string,
  value: string,
  days?: number | string,
  path?: string,
  secure?: boolean,
  httpOnly?: boolean
) => {
  let expires = "";
  const getDomain = () => {
    const domain = location.hostname;
    if (domain === "localhost") {
      return domain;
    }

    const parts = domain.split(/[.]+/);
    return `.${parts.slice(-2).join(".")}`;
  };

  if (days) {
    if (typeof days === 'number') {
      const date: Date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "; expires=Session";
    }
  }

  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    "; path=" +
    (path ? path : "/") +
    "; domain=" +
    getDomain();
};

export const getCookie = (name: string): string | null => {
  // Create a new array with each cookie separated by semicolons and spaces.
  const cookies = document.cookie.split("; ").map((c) => {
    const [key, value] = c.split("=");
    return { key, value };
  });

  // Find the cookie with the given name.
  const cookie = cookies.find((c) => c.key === name);

  // Return the value of the cookie if found, otherwise return null.
  return cookie ? decodeURIComponent(cookie.value) : null;
};

export const compareObject = (object1: any, object2: any): boolean => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const youtubeParser = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export const vimeoParser = (url: string) => {
  const regExp =
    /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(video\/)?([0-9]+)/;
  const match = url.match(regExp);
  return match && match[5] ? match[5] : false;
};

export const videoUrlParser = (url: string) => {
  let videoId;

  videoId = youtubeParser(url);
  if (videoId) {
    return { platform: "youtube", videoId };
  }

  videoId = vimeoParser(url);
  if (videoId) {
    return { platform: "vimeo", videoId };
  }

  return { platform: null, videoId: null };
};

export const arrayMove = <T>(arr: T[], oldIndex: number, newIndex: number): T[] => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined as any);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const smoothAutoScroll = (scrollbar: any, targetPosition: number): void => {
  const startPosition = scrollbar.ps.lastScrollTop;
  const distance = targetPosition - startPosition;
  const duration = 200;

  let startTime: number | null = null;

  const step = (timestamp: number) => {
    if (startTime === null) {
      startTime = timestamp;
    }

    const progress = timestamp - startTime;
    const position =
      startPosition + easeInOutCubic(progress / duration) * distance;

    scrollbar.$el.scrollTop = position;

    if (progress < duration) {
      requestAnimationFrame(step);
    }
  };

  const easeInOutCubic = (t: number): number => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  requestAnimationFrame(step);
};
