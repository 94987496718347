import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0fb67f9e")
const _hoisted_1 = ["src", "aria-label"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_loader = _resolveComponent("skeleton-loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("img", {
      src: _ctx.video ? _ctx.vimeoThumbnailPath : _ctx.getPath,
      "aria-label": _ctx.alt,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args))),
      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageLoadError && _ctx.onImageLoadError(...args)))
    }, null, 40, _hoisted_1), [
      [_vShow, _ctx.isLoaded]
    ]),
    (!_ctx.isLoaded)
      ? (_openBlock(), _createBlock(_component_skeleton_loader, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}