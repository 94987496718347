
import { Options, mixins } from "vue-class-component";
import { RoutingUtilityMixin, HasAccessToAssetMixin } from "@/mixins";
import ImageWithLoader from "@/components/ui/BaseImage/ImageWithLoader.vue";
import { AssetsInterface, AssetsTypesInterface, AssetType } from "@/types";
import { useMq } from "vue3-mq";
import { PropType } from "vue";
import { settingsModule, usersModule } from "@/store";

@Options({
  name: "TreeNode",
  props: {
    asset: {
      type: Object as PropType<AssetsInterface>,
    },
  },
  components: {
    ImageWithLoader,
  },
})
export default class TreeNode extends mixins(
  RoutingUtilityMixin,
  HasAccessToAssetMixin
) {
  private mq = useMq();
  protected asset!: AssetsInterface;

  protected get isModels(): AssetType {
    return AssetType.Models;
  }

  protected get isVideo(): AssetType {
    return AssetType.Video;
  }

  protected get isLoadingUserAccess(): boolean {
    return usersModule.isLoading;
  }

  protected isActive(asset: AssetsInterface): boolean {
    const { assetSlug } = this.$route.params;
    if (!assetSlug) return false;

    return asset.slug === assetSlug;
  }

  protected getAssetsTypesAsString(types: Array<number>): string {
    return settingsModule.getSettings
      .map((s: AssetsTypesInterface) => types.includes(s.id) && s.title)
      .filter(Boolean)
      .join(" / ");
  }

  protected onSelect(): void {
    if (["xs", "sm", "md"].includes(this.mq.current))
      this.emitter.emit("asset-item-is-selected");
  }
}
