
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import SkeletonLoader from "@/components/ui/BaseSkeletonImage/index.vue";
import { videoUrlParser } from "@/utils";
import getVomeoThumbnail from "get-vimeo-thumbnail";

@Options({
  name: "Image",
  components: {
    SkeletonLoader,
  },
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String as PropType<string>,
    },
    global: {
      type: Boolean,
      default: true,
    },
    d3model: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Image extends Vue {
  private basePath = process.env.VUE_APP_RESOURCE_URL;
  private src!: string;
  private global!: boolean;
  private d3model!: boolean;
  protected video!: boolean;
  protected isLoaded = false;
  private isError = false;
  protected vimeoThumbnailPath: string | string[] = [];

  get getPath(): string {
    if (this.global) {
      if (this.isError || !this.src) {
        return require(`@/assets/images/icons/broken-image.svg`);
      }

      if (this.d3model) {
        return `${process.env.VUE_APP_WEB_VIEWER_CONTENT_URL}${this.src}.preview.png`;
      }

      return `${this.basePath}/${this.src}`;
    }

    try {
      return require(`@/assets/images/${this.src}`);
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }

  protected onImageLoad(): void {
    this.isLoaded = true;
  }

  protected onImageLoadError(): void {
    this.isError = true;
  }

  protected async getVideoPath(): Promise<string | string[]> {
    const result = videoUrlParser(this.src);

    if (result.platform === "vimeo") {
      const [smallThumbnail, mediaThumbnail, largeThumbnail] =
        await getVomeoThumbnail(this.src);
      return smallThumbnail;
    } else {
      return `${process.env.VUE_APP_YOUTUBE_VIDEO_IMAGE_URL}${result.videoId}/default.jpg`;
    }
  }

  async mounted(): Promise<void> {
    this.vimeoThumbnailPath = await this.getVideoPath();
  }
}
